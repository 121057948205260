import React from 'react';
import { Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { ContentfulArtist } from 'types';

import { artistName } from 'utils/artists';
import styles from '../Artist.module.css';

interface IArtistReleaseProps {
  to: string;
  img: FluidObject;
  title: string;
  artists: Pick<ContentfulArtist, 'name'>[];
  isCompilation: boolean;
}

export const ArtistRelease: React.FC<IArtistReleaseProps> = ({
  to,
  img,
  title,
  artists = [],
  isCompilation,
}) => (
  <article className={styles.release}>
    <div className={styles.releaseImgContainer}>
      <Link to={to} className={styles.releaseImgLinkContainer}>
        <Img
          fluid={img}
          alt={`${title} cover art`}
          className={styles.releaseImg}
        />
      </Link>
    </div>

    <div className={styles.releaseDetails}>
      <h3 className={styles.releaseTitle}>
        <Link to={to} className="color-inherit dim">
          {title}
        </Link>
      </h3>
      <span className={styles.releaseSubtitle}>
        {artistName(artists as ContentfulArtist[], isCompilation)}
      </span>
    </div>
  </article>
);
