import React from 'react';
import Img from 'gatsby-image';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetArtistDetailsQuery } from 'types';

import { RELEASE_DETAILS_ROUTE } from 'utils/routes';
import { ArtistRelease } from './ArtistRelease';

import styles from './Artist.module.css';

interface IArtistDetailsProps {
  post: GetArtistDetailsQuery['post'];
  releases: GetArtistDetailsQuery['releases'];
}

interface IArtistDetailsLink {
  url: string;
  name: string;
}

const isLink = (link: any): link is IArtistDetailsLink => {
  return typeof link === 'object';
};

export const Artist: React.FC<IArtistDetailsProps> = ({
  post: { name, description, images, soundcloudUrl, instagramUrl, facebookUrl },
  releases,
}) => {
  let links: IArtistDetailsLink[] = [
    { url: soundcloudUrl, name: 'soundcloud' },
    { url: instagramUrl, name: 'instagram' },
    { url: facebookUrl, name: 'facebook' },
  ];

  links = filter(links, link => link.url).map(l => (isLink(l) ? l : null));

  const Images = images && (
    <Img
      fluid={images[0].fluid}
      className={styles.artistImg}
      alt={images[0].description}
    />
  );

  return (
    <>
      <div className={styles.headerBg} />
      <header className={styles.header}>
        <figure className={styles.artistImgContainer}>{Images}</figure>
        <div className={styles.titleSection}>
          <h1 className={styles.title}>{name}</h1>
          {links && (
            <nav>
              {links.map(link => (
                <a
                  key={link.url}
                  href={link.url}
                  className={styles.headerIcon}
                  title={capitalize(link.name)}
                >
                  <FontAwesomeIcon
                    icon={['fab', link.name as IconName]}
                    size="1x"
                  />
                </a>
              ))}
            </nav>
          )}
        </div>
      </header>
      <div className={styles.body}>
        {description && (
          <section
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        )}
        {!isEmpty(releases) && (
          <section className={styles.releasesSection}>
            <h2 className={styles.sectionTitle}>Appears On</h2>
            <div className={styles.releases}>
              {map(
                releases.edges,
                ({
                  node: { slug, title, coverArt = [], artists, isCompilation },
                }) => {
                  return (
                    <ArtistRelease
                      key={slug}
                      to={RELEASE_DETAILS_ROUTE(slug)}
                      title={title}
                      img={get(coverArt, 'fluid', '')}
                      artists={artists}
                      isCompilation={isCompilation}
                    />
                  );
                }
              )}
            </div>
          </section>
        )}
      </div>
    </>
  );
};
