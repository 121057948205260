import sortBy from 'lodash/sortBy';
import { ContentfulArtist } from 'types';

export const artistName = (
  artists: ContentfulArtist[],
  isCompilation: boolean
): string => (isCompilation ? `Various Artists` : artists[0].name);

export const sortArtists = (
  artists: ContentfulArtist[],
  prop: string
): ContentfulArtist[] => sortBy(artists, prop);

// Asserts that the parameter is a ContentfulArtist[]
// object as GQL codegen doesn't generate 1:1 types
// eslint-disable-next-line
export const isArtists = (a: any): a is ContentfulArtist[] =>
  typeof a === 'object';

// Asserts that the parameter is a ContentfulArtist['description']
// object as GQL codegen doesn't generate 1:1 types
export const isArtistDescription = (
  // eslint-disable-next-line
  d: any
): d is ContentfulArtist['description'] => typeof d === 'object';
