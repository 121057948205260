import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { ARTIST_DETAILS_SEO } from 'utils/seo';

import Layout from 'components/layout';
import { Artist } from 'components/Artists/Artist';

import { GetArtistDetailsQuery } from 'types';

interface IArtistDetailsProps {
  data: GetArtistDetailsQuery;
  location: Location;
}

export const ArtistTemplate: React.FC<IArtistDetailsProps> = ({
  data: {
    post,
    post: { slug, name, description, images },
    releases,
  },
}) => {
  return (
    <Layout
      title={ARTIST_DETAILS_SEO.title(name)}
      description={ARTIST_DETAILS_SEO.description(
        name,
        get(description, 'childMarkdownRemark.excerpt')
      )}
      image={ARTIST_DETAILS_SEO.image(images[0].fluid.src)}
      pathname={ARTIST_DETAILS_SEO.pathname(slug)}
    >
      <Artist post={post} releases={releases} />
    </Layout>
  );
};

export default ArtistTemplate;

export const artistDetailsQuery = graphql`
  query GetArtistDetails($slug: String!) {
    post: contentfulArtist(slug: { eq: $slug }) {
      slug
      name
      images {
        description
        fluid(maxWidth: 350) {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          excerpt(pruneLength: 155)
          html
        }
      }
      soundcloudUrl
      instagramUrl
      facebookUrl
    }
    releases: allContentfulRelease(
      filter: { artists: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          artists {
            name
          }
          title
          slug
          isCompilation
          coverArt {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
